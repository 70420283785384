import { useNavigate } from "react-router-dom";
import { browserName, isIOS } from "react-device-detect";
import LightTextLanding from "../../../../components/atoms/LandingText/LightTextLanding";
import BtnStandardLanding from "../../../../ds/BtnStandardLanding/BtnStandardLanding";
import { useContent, useLanguages } from "../../../../hooks/content";
import { Returntext } from "../../common/returnText";
import { box_cover } from "../../../../assets/landing";
function Starter() {
  const { contentManagementData } = useContent();
  const { lang } = useLanguages();
  const navigate = useNavigate();
  function generateHTML(dynamicText) {
    const words = dynamicText.split(" ");

    words[4] = `<span style="color: #6AD67D;">${
      words[4] ? words[4] : ""
    }</span>`;

    const htmlString = `
      <div class="boldFont home_main_header">
        ${words.join(" ")}
      </div>
    `;

    return htmlString;
  }

  const generatedHTML = generateHTML(
    Returntext(contentManagementData, "home_h1", lang)
  );

  return (
    <div className="container_inside flex_between reverce_box">
      <div className="flex_col_start starting_point" style={{ gap: "20px" }}>
        <div className="boldFont home_main_header">
          <div dangerouslySetInnerHTML={{ __html: generatedHTML }} />
        </div>
        <div className="home_main_sub_header">
          <LightTextLanding
            text_color="#000000"
            text={Returntext(contentManagementData, "home_h2", lang)}
            text_size="20px"
          />
        </div>
        <div>
          <BtnStandardLanding
            mode="active"
            txt={Returntext(contentManagementData, "home_btn", lang)}
            disabled={false}
            styles={{ padding: "20px" }}
            size={"standard"}
            icon={false}
            mainColor={"green"}
            onClick={() => navigate("/sign-up")}
          />
        </div>
      </div>
      <div className="img_container_starter">
        <img
          className="home_main_img"
          alt="box"
          src={
            browserName === "Safari" || isIOS
              ? box_cover
              : `${process.env.PUBLIC_URL}/images/animations/box.webp`
          }
          loading="lazy"
        />
      </div>
    </div>
  );
}
export default Starter;
