import React, { useEffect, useState } from "react";
import "./addressDrawer.scss";
import BtnCircular from "../../../ds/BtnCircular/BtnCircular";
import AddressDrawerBody from "../../molecules/AddressDrawerBody/AddressDrawerBody";
import BtnStandardIconText from "../../../ds/BtnStandardIconText/BtnStandardIconText";
import { useUserData } from "../../../hooks/users";
import useAddressPost2 from "../../../hooks/address";
import axios from "axios";
import Cookies from "js-cookie";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";
import { ReactComponent as CloseIcon } from "../../../icons/x.svg";

const AddressDrawer = ({ cancelClick, setUserAddress, style }) => {
  const [title, setTitle] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [instructions, setInstructions] = useState("");
  const [currentLocation, setCurrentLocation] = useState({
    lat: 41.7056322,
    lng: 44.7870996,
  });

  const [tag, setTag] = useState("home");

  // const { data: userData } = useUserData();
  const userData = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const getUserCity = () => {
      fetch("https://ipapi.co/json/")
        .then((response) => response.json())
        .then((data) => {
          const city = data.city;

          setCity(city);
        })
        .catch((error) => {
          console.error("Error getting user city:", error);
        });
    };

    getUserCity();
  }, []);

  const [autocomplete, setAutocomplete] = useState(null);

  useEffect(() => {
    if (autocomplete) {
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (!place.geometry) {
          console.log("Place not found");
          return;
        }
        const { formatted_address } = place;
        setAddress(formatted_address);
        setCity(formatted_address.split(", ")[1]);
        setCurrentLocation({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        });
      });
    }
  }, [autocomplete, setAddress]);

  const { refetchUserDataOnSuccess } = useAddressPost2(); // Initialize the hook

  const handleConfirmAddress = async () => {
    try {
      const access_token = Cookies.get("access_token");
      const addressData = {
        address,
        status: "ACTIVE",
        customer_id: userData.id,
        tag,
        title,
        city,
        additionalInstructions: instructions,
        lang: currentLocation ? currentLocation.lng.toString() : "",
        lat: currentLocation ? currentLocation.lat.toString() : "",
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/customer-addresses?accessToken=${access_token}`,
        addressData
      );
      setAddress(response.data.id);

      cancelClick();
      setUserAddress(response.data);
      refetchUserDataOnSuccess();
    } catch (error) {
      refetchUserDataOnSuccess();
      console.error("Error posting address:", error);
    }
  };

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  return (
    <div className="address-drawer" style={style}>
      <div className="shadow" onClick={cancelClick}></div>
      <div className="drawer-wrap">
        <div className="header">
          <div className="left"></div>

          <div className="middle">
            {Returntext(
              contentManagementData,
              "DeliverySettingsPopUpHeadline",
              lang
            )}
          </div>

          <BtnCircular
            size={"sm"}
            mainColor={"white"}
            svg={<CloseIcon />} // Use the SVG component here
            disabled={false}
            mode={"active"}
            onClick={cancelClick}
          />
        </div>
        <div className="body">
          <AddressDrawerBody
            tag={tag}
            setTag={setTag}
            title={title}
            setTitle={setTitle}
            setAutocomplete={setAutocomplete}
            address={address}
            setAddress={setAddress}
            city={city}
            setCity={setCity}
            instructions={instructions}
            setInstructions={setInstructions}
            setCurrentLocation={setCurrentLocation}
            currentLocation={currentLocation}
          />
        </div>
        <div className="footer">
          <div className="btns-wrap">
            <BtnStandardIconText
              mode=""
              txt={Returntext(contentManagementData, "cancel", lang)}
              disabled={false}
              size={"standard"}
              icon={false}
              mainColor={"white"}
              onClick={cancelClick}
            />

            <BtnStandardIconText
              mode=""
              txt={Returntext(contentManagementData, "confirm", lang)}
              disabled={false}
              size={"standard"}
              icon={false}
              mainColor={"green"}
              onClick={() => handleConfirmAddress()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddressDrawer;
