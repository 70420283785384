import React, { useState } from "react";
import "./paymentSummary.scss";
import OrderPriceDetails from "../OrderPriceDetails/OrderPriceDetails";
import PaymentBox from "../PaymentBox/PaymentBox";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";

const PaymentSummary = ({
  selectedOrders,
  totalValue,
  nextClick,
  setActiveOrder,
  activeOrder,
  address,
  cancelClick,
  useraddress,
}) => {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  return (
    <div className="payment-summary">
      {/* <div className="details">
        <div className="title">
          {Returntext(contentManagementData, "PBT", lang)}
        </div>
        {selectedOrders.map((order, i) => (
          <OrderPriceDetails order={order} key={i} />
        ))}
      </div> */}

      <PaymentBox
        totalValue={totalValue}
        selectedOrders={selectedOrders}
        nextClick={nextClick}
        setActiveOrder={setActiveOrder}
        activeOrder={activeOrder}
        address={address}
        cancelClick={cancelClick}
        useraddress={useraddress}
      />
    </div>
  );
};

export default PaymentSummary;
