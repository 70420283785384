import React from "react";
import "./checkBox.scss";
import { ReactComponent as CheckedIcon } from "../../../icons/checked.svg";
import { ReactComponent as CheckedDisabledIcon } from "../../../icons/checkedD.svg";

const CheckBox = ({ state, disable, onClick }) => {
  return (
    <div
      className={`check-box ${disable && "disable"} ${
        state === "active" && "active"
      }`}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      {state === "active" && disable && (
        <CheckedDisabledIcon className="icon" />
      )}
      {!disable && state === "active" && <CheckedIcon className="icon" />}
    </div>
  );
};

export default CheckBox;
