import React, { useState } from "react";
import BtnStandardIconText from "../BtnStandardIconText/BtnStandardIconText";
import UploadBox from "../UploadBox/UploadBox";
import InputGz from "../InputGz/InputGz";
import AutocompleteInput from "../AutocompleteInput/AutocompleteInput";
import { useContent, useLanguages } from "../../hooks/content";
import { Returntext } from "../../pages/Landings/common/returnText";
import useCategoryArray from "./category";

const NotDeclaredDrawerBody = ({
  itemCategory,
  setItemCategory,
  amount,
  setAmount,
  amountError,
  itemCategoryError,
}) => {
  const [sActive, setSActive] = useState(false);
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  const categories = useCategoryArray();

  return (
    <div className="not-declared-drawer-body">
      <AutocompleteInput
        type={"text"}
        value={itemCategory}
        setter={setItemCategory}
        label={Returntext(contentManagementData, "chooseCategory", lang)}
        nid={"category"}
        disabled={false}
        errorM={itemCategoryError}
        sActive={sActive}
        setSActive={setSActive}
        data={categories}
      />

      <div className="amount">
        <InputGz
          type={"text"}
          value={amount}
          setter={(newValue) => {
            // Use regular expression to replace non-digit and non-decimal point characters with empty string
            const sanitizedValue = newValue.replace(/[^0-9.]/g, "");
            // Update the amount state with the sanitized value
            setAmount(sanitizedValue);
          }}
          label={Returntext(contentManagementData, "amount", lang)}
          nid={"amount"}
          disabled={false}
          errorM={amountError}
        />
        {/* 
        <div className="s-msg">
          <div className="left">You will need to pay 18% VAT</div>
          <div className="right">Tell me more</div>
        </div> */}
      </div>
    </div>
  );
};

export default NotDeclaredDrawerBody;
