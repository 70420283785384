import React from "react";
import "./orderTable.scss";
import OrderTableCell from "../OrderTableCell/OrderTableCell";
import TableBtn from "../TableBtn/TableBtn";
import { useContent, useLanguages } from "../../hooks/content";
import { Returntext } from "../../pages/Landings/common/returnText";
import OrderPopOver from "../../components/atoms/OrderPopOver/OrderPopOver";
import { BrowserView } from "react-device-detect";
import { ReactComponent as CompleteIcon } from "../../icons/complete.svg";
import { ReactComponent as CompleteDisabledIcon } from "../../icons/complete-d.svg";
import { ReactComponent as DeclareIcon } from "../../icons/declare.svg";
import { ReactComponent as TrackingIcon } from "../../icons/tracking.svg";
import { ReactComponent as TrackingDisabledIcon } from "../../icons/tracking-d.svg";
import { ReactComponent as CustomIcon } from "../../icons/custom-tb.svg";
import { ReactComponent as CustomDisabledIcon } from "../../icons/custom-d.svg";
import { ReactComponent as DeliveryIcon } from "../../icons/delivery-tb.svg";
import { ReactComponent as DeliveryDisabledIcon } from "../../icons/delivery-d.svg";
import { ReactComponent as PayIcon } from "../../icons/pay.svg";
import { ReactComponent as PayDisabledIcon } from "../../icons/pay-disable.svg";

const OrderTable = ({
  orders,
  declareClick,
  setActiveOrder,
  customClick,
  paymentClick,
  deliveryClick,
  trackingWayClick,
  vatClick,
  setDeleteOrder,
  setActiveItem,
  payedClick,
}) => {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  const payTxt = (order) => {
    if (order?.buttons?.payment_enable) {
      return `${Returntext(
        contentManagementData,
        "btn_pay",
        lang
      )} ₾${order.prices.total.toFixed(2)}`;
    } else if (!order?.buttons?.payment_enable && order?.payed) {
      return Returntext(contentManagementData, "btn_payed", lang);
    } else {
      return Returntext(contentManagementData, "btn_pay", lang);
    }
  };

  const getCount = (count) => {
    if (count === "1") {
      return "1";
    } else if (count === "2" || count === "3") {
      return "2";
    } else if (count === "4" || count === "5") {
      return "3";
    }
  };

  return (
    <div className="order-table">
      {orders.map((order) => (
        <OrderTableCell
          order={order}
          key={order.id}
          setActiveItem={setActiveItem}
          setDeleteItem={setDeleteOrder}
          buttons={
            <>
              <TableBtn
                mode=""
                mainColor="grayBorder"
                txt={Returntext(contentManagementData, "btn_declare", lang)}
                disabled={false}
                icon={true}
                iconLeft={true}
                iconRight={order.declaration_status === "DECLERED"}
                rightSvg={<CompleteIcon />}
                rightSvgDisabled={<CompleteDisabledIcon className="icon" />}
                svg={<DeclareIcon />}
                onClick={() => {
                  setActiveOrder(order);
                  declareClick();
                }}
              />
              <TableBtn
                mode=""
                mainColor="grayBorder"
                txt={Returntext(contentManagementData, "btn_tracking", lang)}
                disabled={order.status === "AWAITING"}
                icon={true}
                iconLeft={true}
                iconRight={!!order.delivery_status}
                rightSvg={<CompleteIcon className="icon" />}
                rightSvgDisabled={<CompleteDisabledIcon className="icon" />}
                svg={<TrackingIcon className="icon" />}
                svgDisabled={<TrackingDisabledIcon className="icon" />}
                onClick={() => {
                  setActiveOrder(order);
                  trackingWayClick();
                }}
              />
              <TableBtn
                mode=""
                mainColor="grayBorder"
                txt={Returntext(contentManagementData, "btn_custom", lang)}
                disabled={!order?.buttons?.custom_enable}
                icon={true}
                iconLeft={true}
                iconRight={order?.buttons?.custom_right_icon_enable}
                rightSvg={<CompleteIcon className="icon" />}
                rightSvgDisabled={<CompleteDisabledIcon className="icon" />}
                svg={<CustomIcon className="icon" />}
                svgDisabled={<CustomDisabledIcon className="icon" />}
                rightDoubleIcon={
                  order?.buttons?.custom_right_double_icon_enable
                }
                onClick={() => {
                  if (order.vat) {
                    setActiveOrder(order);
                    vatClick();
                  } else {
                    setActiveOrder(order);
                    customClick();
                  }
                }}
                count={getCount(order?.custom_status)}
              />
              <TableBtn
                mode=""
                mainColor="grayBorder"
                txt={Returntext(contentManagementData, "btn_delivery", lang)}
                disabled={!order?.buttons?.delivery_enable}
                icon={true}
                iconLeft={true}
                iconRight={!!order.timeSlot}
                rightSvg={<CompleteIcon className="icon" />}
                rightSvgDisabled={<CompleteDisabledIcon className="icon" />}
                svg={<DeliveryIcon className="icon" />}
                svgDisabled={<DeliveryDisabledIcon className="icon" />}
                onClick={() => {
                  setActiveOrder(order);
                  deliveryClick();
                }}
              />
              <TableBtn
                fullWidth={true}
                mode=""
                mainColor={
                  order?.prices?.total && !order.payed ? "red" : "grayBorder"
                }
                txt={payTxt(order)}
                disabled={order?.buttons?.payment_enable ? false : true}
                svg={<PayIcon className="icon" />}
                svgDisabled={<PayDisabledIcon className="icon" />}
                icon={true}
                iconLeft={order?.prices?.total && !order.payed ? false : true}
                iconRight={!!order.payed}
                rightSvg={<CompleteIcon className="icon" />}
                rightSvgDisabled={<CompleteDisabledIcon className="icon" />}
                onClick={() => {
                  setActiveOrder(order);
                  if (order?.buttons?.payment_enable) {
                    paymentClick();
                  } else {
                    payedClick();
                  }
                }}
              />
              <BrowserView>
                {order.status === "AWAITING" && (
                  <OrderPopOver
                    item={order}
                    setActiveItem={setActiveItem}
                    setDeleteItem={setDeleteOrder}
                  />
                )}
              </BrowserView>
            </>
          }
        />
      ))}
    </div>
  );
};

export default OrderTable;
