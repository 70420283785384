import axios from "axios";
import BtnLightCircularIconLg from "../../components/atoms/BtnLightCircularIconLg/BtnLightCircularIconLg";
import OnboardWelcomeTextLg from "../../components/atoms/OnboardWelcomeTextLg/OnboardWelcomeTextLg";
import OnboardWelcomeTextMd from "../../components/atoms/OnboardWelcomeTextMd/OnboardWelcomeTextMd";
import OnboardFormBody from "../../components/molecules/OnboardFormBody/OnboardFormBody";
import OnboardFormFooter from "../../components/molecules/OnboardFormFooter/OnboardFormFooter";
import OnboardFormHeader from "../../components/molecules/OnboardFormHeader/OnboardFormHeader";
import "./onboarding.scss";

import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { useUserData } from "../../hooks/users";
import { useContent, useLanguages } from "../../hooks/content";
import { Returntext } from "../Landings/common/returnText";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const Onboarding = ({ setLoader }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  // const { data: userData } = useUserDatav();
  const userData = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const [stage, setStage] = useState(0);
  const { refetch } = useUserData();

  const [sdaVerified, setSdaVerified] = useState(false);

  const [fistName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lasName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [firstNameG, setFirstNameG] = useState("");
  const [firstNameGError, setFirstNameGError] = useState("");
  const [lasNameG, setLastNameG] = useState("");
  const [lastNameGError, setLastNameGError] = useState("");
  const [email, setEmail] = useState(user.email);
  const [companyName, setCompanyName] = useState("");
  const [companyNameError, setCompanyNameError] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [companyIdError, setCompanyIdError] = useState("");
  const [idNum, setIdNum] = useState("");
  const [idNumError, setIdNumError] = useState("");
  const [phone, setPhone] = useState("");
  const [isChecked, setIsChecked] = useState(true);
  const [phoneError, setPhoneError] = useState(false);
  const [activeMode, setActiveMode] = useState("resident");
  const [emailError, setEmailError] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleDetails = async () => {
    try {
      setFirstNameError("");
      setLastNameError("");
      setFirstNameGError("");
      setLastNameGError("");
      setCompanyNameError("");
      setCompanyIdError("");
      setIdNumError("");

      if (!fistName) {
        setFirstNameError("First name is required");
        enqueueSnackbar(
          Returntext(contentManagementData, "ErrorMessages-settings", lang),
          {
            variant: "error",
          }
        );
        return;
      }
      if (!lasName) {
        setLastNameError("Last name is required");
        enqueueSnackbar(
          Returntext(contentManagementData, "ErrorMessages-settings", lang),
          {
            variant: "error",
          }
        );
        return;
      }
      if (!phone && !userData.phone) {
        setPhoneError(true);

        enqueueSnackbar(
          Returntext(contentManagementData, "ErrorMessages-settings", lang),
          {
            variant: "error",
          }
        );
        return;
      } else {
        setPhoneError(false);
      }

      if (!email && !userData.email) {
        setEmailError(true);
        enqueueSnackbar(
          Returntext(contentManagementData, "ErrorMessages-settings", lang),
          {
            variant: "error",
          }
        );
        return;
      } else {
        setEmailError(false);
      }
      const access_token = Cookies.get("access_token");

      await axios.put(
        `${process.env.REACT_APP_API_URL}/customers/${user.id}?accessToken=${access_token}`,
        {
          first_name: fistName,
          last_name: lasName,
          first_name_ka: firstNameG,
          last_name_ka: lasNameG,
          verified: true,
          id_number: idNum,
          user_id: user.user_id,
          phone: phone !== "" ? phone : userData.phone,
          email: email !== "" ? email : userData.email,
          company_id: companyId,
          company_info: companyName,
          marketing_permissions: isChecked,
          registered: userData.phone ? userData.phone : userData.email,
          isNotResident: activeMode === "resident" ? false : true,
        }
      );

      setIdNumError(false);
      refetch();

      setLoader(true);
      const timeout = setTimeout(() => {
        navigate("/onboarding/delivery-address");
      }, 1000);

      return () => clearTimeout(timeout);
    } catch (error) {
      setIdNumError(true);

      enqueueSnackbar(
        Returntext(contentManagementData, "ErrorMessages-signin7", lang),
        {
          variant: "error",
        }
      );
    }
  };
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  return (
    <div className="onboarding">
      <div className="onboarding-wrap">
        <div className="body-wrap">
          <div className="left">
            <div className="content-wrap">
              <div className="top">
                <BtnLightCircularIconLg />
                <OnboardWelcomeTextLg
                  txt={Returntext(
                    contentManagementData,
                    "onboardWelcome",
                    lang
                  )}
                />
              </div>
              <OnboardWelcomeTextMd
                txt={Returntext(contentManagementData, "onboardSW", lang)}
              />
              <img src="/images/heros/onboard.png" alt="" />
            </div>
          </div>

          <div className="right">
            <OnboardFormHeader
              stage={stage}
              maintxt={
                stage === 0
                  ? Returntext(contentManagementData, "onboardPI", lang)
                  : Returntext(contentManagementData, "onboardMapH", lang)
              }
              subTxt={
                stage === 0
                  ? Returntext(contentManagementData, "onboardPIS", lang)
                  : Returntext(contentManagementData, "onboardMapHS", lang)
              }
            />
            <OnboardFormBody
              stage={stage}
              setSdaVerified={setSdaVerified}
              fistName={fistName}
              fistNameError={firstNameError} // Add error value for first name
              lasName={lasName}
              lasNameError={lastNameError} // Add error value for last name
              firstNameG={firstNameG}
              firstNameGError={firstNameGError} // Add error value for first name in Georgian
              lasNameG={lasNameG}
              lasNameGError={lastNameGError} // Add error value for last name in Georgian
              email={email}
              companyName={companyName}
              companyNameError={companyNameError} // Add error value for company name
              companyId={companyId}
              companyIdError={companyIdError} // Add error value for company ID
              idNum={idNum}
              idNumError={idNumError} // Add error value for ID number
              setFirstName={setFirstName}
              setLastName={setLastName}
              setFirstNameG={setFirstNameG}
              setLastNameG={setLastNameG}
              setEmail={setEmail}
              setCompanyName={setCompanyName}
              setCompanyId={setCompanyId}
              setIdNum={setIdNum}
              setStage={setStage}
              phone={phone}
              setPhone={setPhone}
              activeMode={activeMode}
              setActiveMode={setActiveMode}
              phoneError={phoneError}
              emailError={emailError}
            />
            <OnboardFormFooter
              disabled={sdaVerified ? false : true}
              stage={stage}
              setStage={setStage}
              sdaVerified={sdaVerified}
              handleDetails={handleDetails}
              isChecked={isChecked}
              setIsChecked={setIsChecked}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
