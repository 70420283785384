import React, { useState } from "react";
import "./inputGz.scss";

const InputGz = ({
  type,
  value,
  setter,
  label,
  nid,
  disabled,
  errorM,
  style,
  noBorder,
  onKeyDown,
  hasLabel,
}) => {
  const [lbClick, setLbClick] = useState(false);

  return (
    <div
      className={
        lbClick || value.length !== 0
          ? `input-gz-wrap  ${!hasLabel && "focused"} ${
              noBorder && "no-border"
            } ${disabled ? "disabled" : ""} ${errorM ? "error" : ""}`
          : `input-gz-wrap ${noBorder && "no-border"} ${
              disabled ? "disabled" : ""
            } ${errorM ? "error" : ""}`
      }
      onFocus={() => {
        if (!hasLabel) {
          setLbClick(true);
        }
      }}
      onBlur={() => {
        if (!hasLabel) {
          setLbClick(false);
        }
      }}
    >
      {!onKeyDown ? (
        <input
          id={nid}
          name={nid}
          className="input-gz"
          type={type}
          value={value}
          onChange={(e) => setter(e.target.value)}
          disabled={disabled}
          style={style}
        />
      ) : (
        <input
          id={nid}
          name={nid}
          className="input-gz"
          type={type}
          value={value}
          onChange={(e) => setter(e.target.value)}
          disabled={disabled}
          style={style}
          onKeyDown={onKeyDown}
        />
      )}

      {!hasLabel && (
        <label htmlFor={nid} className="label">
          {label}
        </label>
      )}
    </div>
  );
};

export default InputGz;
