import React, { useEffect, useState } from "react";
import "./NavbarMobile.scss";
import { Link, useLocation } from "react-router-dom";
import OrderDrawerBody from "../../../ds/OrderDrawerBody/OrderDrawerBody";
import Drawer from "../../../ds/Drawer/Drawer";
import {
  useAddOrder,
  useCreateDeclaration,
  useOrders,
} from "../../../hooks/orders";
import { useSnackbar } from "notistack";
import DeclareDrawerBody from "../../../ds/DeclareDrawerBody/DeclareDrawerBody";
import NotDeclaredDrawerBody from "../../../ds/DeclareBodies/NotDeclaredDrawerBody";
import DeclaredDrawerBody from "../../../ds/DeclareBodies/DeclaredDrawerBody";
import ProductDrawerBody from "../../../ds/ProductDrawerBody/ProductDrawerBody";
import axios from "axios";
import BtnStandardIconText from "../../../ds/BtnStandardIconText/BtnStandardIconText";
import { Returntext } from "../../../pages/Landings/common/returnText";
import { useContent, useLanguages } from "../../../hooks/content";
import Cookies from "js-cookie";

import { ReactComponent as OrdersActiveIcon } from "../../../icons/nav-icons/active/orders.svg";
import { ReactComponent as BoxIcon } from "../../../icons/box-w.svg";
import { ReactComponent as PinActiveIcon } from "../../../icons/nav-icons/active/pin.svg";
import { ReactComponent as PinIcon } from "../../../icons/pin-w.svg";
import { ReactComponent as PlusIcon } from "../../../icons/plus-b.svg";
import { ReactComponent as WalletActiveIcon } from "../../../icons/nav-icons/active/wallet.svg";
import { ReactComponent as WalletIcon } from "../../../icons/wallet-w.svg";
import { ReactComponent as UserActiveIcon } from "../../../icons/user-g.svg";
import { ReactComponent as UserIcon } from "../../../icons/user-w.svg";
import { ReactComponent as AddIcon } from "../../../icons/add.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  setDeclareDrawerActive,
  setTrackingDrawerActive,
} from "../../../redux/actions";

const NavbarMobile = () => {
  const [tracking, setTracking] = useState("");
  const [title, setTitle] = useState("");
  const [country, setCountry] = useState("usa");
  const [amount, setAmount] = useState("");
  const [files, setFiles] = useState([]);
  const [website, setWebsite] = useState("");
  const [itemCategory, setItemCategory] = useState("");
  const [productDrawerActive, setProductDrawerActive] = useState(false);
  const [activeOrder, setActiveOrder] = useState([]);
  const [deleteItem, setDeleteItem] = useState(false);
  const [trackingError, setTrackingError] = useState(false);

  const [activeDec, setActiveDec] = useState([]);
  const location = useLocation();
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  const user = JSON.parse(localStorage.getItem("user"));
  const { orders, refetchOrders } = useOrders(user.id, "AWAITING");
  const { handleAddOrder } = useAddOrder();

  const trackingDrawerActive = useSelector(
    (state) => state.trackingDrawerActive
  );

  const declareDrawerActive = useSelector((state) => state.declareDrawerActive);

  const { enqueueSnackbar } = useSnackbar();

  const DrawerTrigger = (setDrawerActive, drawerActive) => {
    setDrawerActive(!drawerActive);
  };

  const cancelClick = () => {
    dispatch(setDeclareDrawerActive(false));
    dispatch(setTrackingDrawerActive(false));
    setActiveOrder([]);
    setItemCategory("");
    setAmount("");
    setTracking("");
    setTitle("");
    setFiles([]);
    setTrackingError("");
    console.log("first");
  };

  const addOrder = () => {
    if (tracking !== "") {
      handleAddOrder({
        tracking_number: tracking,
        customer_id: user?.id,
        name: title,
        custom_status: "0",
        status: "AWAITING",
      }).then((newOrder) => {
        if (newOrder && newOrder.data) {
          setActiveOrder(newOrder.data);
          dispatch(setDeclareDrawerActive(!declareDrawerActive));
          DrawerTrigger(setTrackingDrawerActive, trackingDrawerActive);
          setTrackingError(false);
          enqueueSnackbar(`Order ${tracking} was created successfully!`, {
            variant: "success",
          });
        } else {
          setTrackingError(true);
          enqueueSnackbar("Order with provided tracking already exists!", {
            variant: "error",
          });
        }
      });
      setTrackingError(false);
    } else {
      enqueueSnackbar("Please Provide Tracking code and title", {
        variant: "error",
      });
      if (tracking === "") {
        setTrackingError(true);
      } else {
        setTrackingError(false);
      }
    }
  };

  const { handleCreateDeclaration } = useCreateDeclaration();

  const [amountError, setAmountError] = useState(false);
  const [websiteError, setWebsiteError] = useState(false);
  const [itemCategoryError, setItemCategoryError] = useState(false);

  const createNewDeclaration = async (activeOrder) => {
    try {
      if (website === "") {
        setWebsiteError(true);
      } else {
        setWebsiteError(false);
      }
      if (amount === "") {
        setAmountError(true);
      } else {
        setAmountError(false);
      }
      if (itemCategory === "") {
        setItemCategoryError(true);
      } else {
        setItemCategoryError(false);
      }

      if (website === "" || amount === "" || itemCategory === "") {
        enqueueSnackbar("Missing Required Parameters", {
          variant: "error",
        });
      } else {
        await handleCreateDeclaration({
          order_id: activeOrder.id,
          website: website,
          category: itemCategory,
          price: amount,
          status: "Declared",
          files: files,
        });
        refetchOrders();
        resetStateVariables();
        setProductDrawerActive(false);
        dispatch(setDeclareDrawerActive(true));
        enqueueSnackbar("Product added successfully!", {
          variant: "success",
        });
      }
    } catch (err) {
      enqueueSnackbar(err.message, {
        variant: "error",
      });
    }
  };

  const resetStateVariables = () => {
    setItemCategory("");
    setAmount("");
    setTracking("");
    setTitle("");
  };

  const bacKClick = () => {
    dispatch(setDeclareDrawerActive(true));
    setProductDrawerActive(false);
    resetStateVariables();
  };

  const triggerMoreProductDrawer = () => {
    setProductDrawerActive(true);
    dispatch(setDeclareDrawerActive(false));
    resetStateVariables();
  };
  const access_token = Cookies.get("access_token");

  useEffect(() => {
    if (
      activeOrder?.declaration !== undefined &&
      activeOrder?.declaration?.length > 0
    ) {
      setWebsite(
        activeOrder?.declaration[0]?.website !== null
          ? activeOrder?.declaration[0]?.website
          : website
      );
    }
  }, [activeOrder]);

  const updateWebsite = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/declarations/${activeOrder.id}?accessToken=${access_token}`,
        {
          website: website,
        }
      );
      enqueueSnackbar("Declarations Website Updated Successfully!", {
        variant: "success",
      });
    } catch (err) {
      console.log(err);
      enqueueSnackbar("Error Updating Declarations Website!", {
        variant: "error",
      });
    }
  };

  const updateDeclaration = async () => {
    try {
      if (
        activeOrder.declaration !== undefined &&
        activeOrder.declaration.length !== 0
      ) {
        await axios.put(
          `${process.env.REACT_APP_API_URL}/orders/${activeOrder.id}?accessToken=${access_token}`,
          {
            declaration_status: "DECLERED",
          }
        );
        updateWebsite();
        refetchOrders();
        dispatch(setDeclareDrawerActive(!declareDrawerActive));
        resetStateVariables();
        setActiveOrder([]);
      } else {
        enqueueSnackbar("You need to add at least one product", {
          variant: "error",
        });
        refetchOrders();
      }
    } catch (error) {
      console.error("Error updating order:", error.message);
    }
  };

  useEffect(() => {
    if (Object.keys(activeOrder).length > 0) {
      setTracking(
        activeOrder.tracking_number !== null
          ? activeOrder.tracking_number
          : tracking
      );
      setTitle(activeOrder.name !== null ? activeOrder.name : title);
      setCountry("usa");
    }
  }, [activeOrder, title, tracking, website]);

  useEffect(() => {
    if (orders.length > 0 && activeOrder.id) {
      const updatedOrder = orders.find((order) => order.id === activeOrder.id);
      if (updatedOrder) {
        setActiveOrder(updatedOrder);
      }
    }
  }, [activeOrder, orders]);

  const TrackingDrawerBody = (
    <OrderDrawerBody
      tracking={tracking}
      setTracking={setTracking}
      title={title}
      setTitle={setTitle}
      country={country}
      setCountry={setCountry}
      titleError={false}
      trackingError={trackingError}
    />
  );

  const TrackingDrawer = (
    <Drawer
      headerTitle={Returntext(
        contentManagementData,
        "trackingDrawerTitle",
        lang
      )}
      body={TrackingDrawerBody}
      onClick={() => {
        addOrder();
      }}
      cancel={cancelClick}
      btnMainTxt={Returntext(contentManagementData, "addTracking", lang)}
    />
  );

  const ProductDrawerBodyContent = (
    <ProductDrawerBody
      itemCategory={itemCategory}
      setItemCategory={setItemCategory}
      amount={amount}
      setAmount={setAmount}
      amountError={amountError}
      itemCategoryError={itemCategoryError}
    />
  );

  const ProductDrawerComponent = (
    <Drawer
      headerTitle={Returntext(contentManagementData, "productDrawerT", lang)}
      body={ProductDrawerBodyContent}
      onClick={() => {
        createNewDeclaration(activeOrder);
      }}
      cancel={bacKClick}
      btnMainTxt={Returntext(contentManagementData, "productDrawerB", lang)}
    />
  );

  const NotDeclaredBody = (
    <NotDeclaredDrawerBody
      itemCategory={itemCategory}
      setItemCategory={setItemCategory}
      amount={amount}
      setAmount={setAmount}
      amountError={amountError}
      itemCategoryError={itemCategoryError}
    />
  );

  const DeclaredBody = (
    <DeclaredDrawerBody
      activeOrder={activeOrder}
      setDeleteItem={setDeleteItem}
      activeDec={activeDec}
      setActiveDec={setActiveDec}
    />
  );

  const DrawerBody = (
    <DeclareDrawerBody
      website={website}
      setWebsite={setWebsite}
      files={files}
      setFiles={setFiles}
      websiteError={websiteError}
      triggerMoreProductDrawer={triggerMoreProductDrawer}
      activeOrder={activeOrder}
      deleteItem={deleteItem}
      setDeleteItem={setDeleteItem}
      activeDec={activeDec}
      drawerBody={
        activeOrder.declaration === undefined ||
        activeOrder.declaration.length === 0
          ? NotDeclaredBody
          : DeclaredBody
      }
      btn={
        activeOrder.declaration === undefined ||
        activeOrder.declaration.length === 0 ? (
          <BtnStandardIconText
            mode=""
            txt={"Add this product"}
            disabled={false}
            size={"standard"}
            icon={false}
            mainColor={"green"}
            onClick={() => createNewDeclaration(activeOrder)}
          />
        ) : (
          <BtnStandardIconText
            mode=""
            txt={"Add More product"}
            disabled={false}
            size={"standard"}
            icon={true}
            iconPosition={"left"}
            mainColor={"grayBorder"}
            onClick={triggerMoreProductDrawer}
            svg={<AddIcon />}
          />
        )
      }
    />
  );

  const DeclarationDrawer = (
    <Drawer
      headerTitle={Returntext(
        contentManagementData,
        "declarationDrawerTitle",
        lang
      )}
      body={DrawerBody}
      onClick={() => {
        updateDeclaration();
        dispatch(setDeclareDrawerActive(!declareDrawerActive));
        resetStateVariables();
        setActiveOrder([]);
      }}
      cancel={cancelClick}
      btnMainTxt={Returntext(contentManagementData, "declareParcel", lang)}
    />
  );

  const dispatch = useDispatch();

  return (
    <div
      className={`navbar-mobile ${trackingDrawerActive && "active"} ${
        declareDrawerActive && "active"
      }  ${productDrawerActive && "active"}`}
    >
      <div className="item-wrap">
        {" "}
        <Link className="item" to={"/orders/AWAITING"}>
          {location.pathname.includes("/orders") ? (
            <OrdersActiveIcon className="icon" />
          ) : (
            <BoxIcon className="icon" />
          )}

          <div className="txt">
            {Returntext(contentManagementData, "mobOrders", lang)}
          </div>
        </Link>
        <Link className="item" to={"/shipping-address"}>
          {location.pathname === "/shipping-address" ? (
            <PinActiveIcon className="icon" />
          ) : (
            <PinIcon className="icon" />
          )}
          <div className="txt">
            {Returntext(contentManagementData, "mobRoom", lang)}
          </div>
        </Link>
        <div
          className="item"
          onClick={() => dispatch(setTrackingDrawerActive(true))}
        >
          <div className="t-btn">
            <PlusIcon />
          </div>
          <div className="txt">
            {Returntext(contentManagementData, "mobAddTrackings", lang)}
          </div>
        </div>
        <Link className="item" to={"/wallet"}>
          {location.pathname === "/wallet" ? (
            <WalletActiveIcon className="icon" />
          ) : (
            <WalletIcon className="icon" />
          )}
          <div className="txt">
            {Returntext(contentManagementData, "mobWallet", lang)}
          </div>
        </Link>
        <Link className="item" to={"/account-settings"}>
          {location.pathname === "/account-settings" ? (
            <UserActiveIcon className="icon" />
          ) : (
            <UserIcon className="icon" />
          )}
          <div className="txt">
            {Returntext(contentManagementData, "mobProf", lang)}
          </div>
        </Link>
      </div>

      {trackingDrawerActive && TrackingDrawer}
      {declareDrawerActive && DeclarationDrawer}
      {productDrawerActive && ProductDrawerComponent}
    </div>
  );
};

export default NavbarMobile;
