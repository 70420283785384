import React, { useEffect, useState } from "react";
import "./paymentBox.scss";
import PriceItem from "../../atoms/PriceItem/PriceItem";
import BtnStandardIconText from "../../../ds/BtnStandardIconText/BtnStandardIconText";
import CardDetails from "../CardDetails/CardDetails";
import { useOrders, useUnpaidOrders } from "../../../hooks/orders";
import { usePaymentCardsQuery } from "../../../hooks/cards";
import axios from "axios";
import { useSnackbar } from "notistack";
import useOrderPrices from "../../../hooks/prices";
import Cookies from "js-cookie";
import PaymentLoader from "../../../loaders/PaymentLoader";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";
import { useNavigate } from "react-router-dom";
import { ReactComponent as WarnRecIcon } from "../../../icons/warn-rec.svg";

const PaymentBox = ({
  selectedOrders,
  nextClick,
  activeOrder,
  address,
  setActiveOrder,
  cancelClick,
}) => {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  const navigate = useNavigate();
  const { data: paymentCards } = usePaymentCardsQuery();
  const user = JSON.parse(localStorage.getItem("user"));
  const { refetchOrders } = useOrders(user.id, "RECEIVED_IN_TBILISI_WAREHOUSE");
  const [loading, setLoading] = useState(false);
  const { refetchUnpaidOrders } = useUnpaidOrders(user.id);

  const [cardInfo, setCardInfo] = useState(
    paymentCards && paymentCards.length > 0
      ? paymentCards.find((card) => card.isDefault)
      : ""
  );

  useEffect(() => {
    setCardInfo(
      paymentCards && paymentCards.length > 0
        ? paymentCards.find((card) => card.isDefault)
        : ""
    );
  }, [paymentCards]);

  const access_token = Cookies.get("access_token");

  const { enqueueSnackbar } = useSnackbar();

  const { data, refetchOrderPrices } = useOrderPrices({
    orders: selectedOrders?.map((item) => item.id),
    address_id: address?.id,
  });

  useEffect(() => {
    refetchOrders();
    refetchUnpaidOrders();
    refetchOrderPrices();
  }, [activeOrder]);

  const longPollingFunction = async (duration, count, handleCheckPayment) => {
    let attempts = 0;

    const poll = async () => {
      if (attempts < count) {
        attempts++;
        const transactionResult = await handleCheckPayment();

        if (transactionResult.payStatus === "COMPLETED") {
          setActiveOrder(transactionResult.orderToUpdate);
          nextClick();
          enqueueSnackbar("Payment Successful! Processing for delivery 🚚✨", {
            variant: "success",
          });
          setLoading(false);
          console.log(transactionResult);
          return;
        } else if (transactionResult.payStatus === "REJECTED") {
          console.log("end");
          setLoading(false);

          enqueueSnackbar("Payment Failed!", {
            variant: "error",
          });
          return;
        } else if (
          transactionResult.payStatus === "PENDING" &&
          attempts === count
        ) {
          console.log("end");
          cancelClick();
          setLoading(false);
          return;
        } else {
          console.log(transactionResult);
          setTimeout(poll, duration);
        }
      } else {
        console.log("end");
        // cancelClick();
        setLoading(false);

        enqueueSnackbar("Payment Failed!", {
          variant: "error",
        });
        return;
      }
    };

    poll();
  };

  const handleCheckPayment = async () => {
    try {
      const requestBody = {
        address_id: address.id,
      };

      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/orders/order_payment_status/${activeOrder.id}?accessToken=${access_token}`,
        requestBody
      );

      refetchOrderPrices();
      refetchUnpaidOrders();
      refetchOrders();
      return res.data;
    } catch (error) {
      console.log(error);
      return "server error";
    }
  };

  const handleTransaction = async () => {
    try {
      if (address !== "" && cardInfo !== "") {
        const arrayOfIds = selectedOrders.map((item) => item.id);
        setLoading(true);
        const requestBody = {
          card: cardInfo.id,
          customer_id: user.id,
          order_ids: arrayOfIds,
          address_id: address.id,
        };

        await axios.post(
          `${process.env.REACT_APP_API_URL}/transactions?accessToken=${access_token}`,
          requestBody
        );

        longPollingFunction(3000, 5, handleCheckPayment);
      } else {
        enqueueSnackbar("Missing Required Parameters", {
          variant: "error",
        });
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      enqueueSnackbar("Error handling payment!", {
        variant: "error",
      });
      setLoading(false);
    }
  };

  return (
    <div className="payment-box">
      {loading && <PaymentLoader />}
      <div className="p-items">
        <PriceItem
          left={Returntext(contentManagementData, "PBTD", lang)}
          right={`${
            data !== undefined && data.length > 0
              ? data[1][0]?.delivery_fee
              : "0"
          }.00₾`}
        />
        {data && data.length > 0 && data[1][0]?.customFee > 0 && (
          <PriceItem
            left={Returntext(contentManagementData, "PBTDS", lang)}
            right={`${
              data && data.length > 0 ? data[1][0]?.customFee : "0"
            }.00₾`}
          />
        )}
        {data && data.length > 0 && data[1][0]?.service_fee > 0 && (
          <PriceItem
            left={Returntext(contentManagementData, "PBTDP", lang)}
            right={`${
              data && data.length > 0 ? data[1][0]?.service_fee : "0"
            }.00₾`}
          />
        )}
      </div>

      <div className="line"></div>

      <div className="total">
        <div className="left">
          {Returntext(contentManagementData, "PBST", lang)}
        </div>
        <div className="right">
          {data !== undefined && data.length > 0
            ? data[1][0]?.totalPrice?.toFixed(2)
            : "0.00"}
          ₾
        </div>
      </div>

      {cardInfo === "" && (
        <div className="info-warn">
          <WarnRecIcon className="icon" />
          <span className="txt" onClick={() => navigate("/wallet")}>
            {Returntext(contentManagementData, "AddCardLink", lang)}
          </span>
        </div>
      )}

      {cardInfo !== "" && (
        <CardDetails cardInfo={cardInfo} setCardInfo={setCardInfo} />
      )}

      <BtnStandardIconText
        onClick={
          address === "" || cardInfo === "" || loading
            ? null
            : handleTransaction
        }
        mode=""
        txt={Returntext(contentManagementData, "pay_btn", lang)}
        disabled={address === "" || cardInfo === "" || loading ? true : false}
        size={"lg"}
        icon={false}
        mainColor={"green"}
      />
    </div>
  );
};

export default PaymentBox;
