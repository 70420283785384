import React from "react";
import "./customBodyS1.scss";
import StepHeader from "../StepHeader/StepHeader";
import Nusxa from "../Nusxa.jsx/Nusxa";
import CustomFeeBox from "../CustomFeeBox/CustomFeeBox";

const CustomBodyS1 = ({
  step,
  main,
  sub,
  setTipsDrawerActive,
  notPaid,
  setNotPaid,
  activeOrder,
}) => {
  return (
    <div className="custom-body-s1">
      <StepHeader
        step={step}
        main={main}
        sub={sub}
        onClick={() => setTipsDrawerActive(true)}
      />

      <CustomFeeBox notPaid={notPaid} setNotPaid={setNotPaid} />

      <div className="data-box">
        <Nusxa activeOrder={activeOrder} />
      </div>
    </div>
  );
};

export default CustomBodyS1;
