import React, { useState } from "react";
import "./AccoundSecondaryEditPP.scss";
import BtnCircular from "../../../ds/BtnCircular/BtnCircular";
import BtnStandardIconText from "../../../ds/BtnStandardIconText/BtnStandardIconText";
import Cookies from "js-cookie";

import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";
import { ReactComponent as CloseIcon } from "../../../icons/x.svg";
import InputGz from "../../../ds/InputGz/InputGz";
import { useUserData } from "../../../hooks/users";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setAPopUpActive } from "../../../redux/actions";

const AccoundSecondaryEditPP = ({ title, msg }) => {
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const userData = JSON.parse(localStorage.getItem("user"));

  const dispatch = useDispatch();

  const handlePhoneChange = (value) => {
    const numericRegex = /^[0-9]*$/;
    if ((numericRegex.test(value) || value === "") && value.length <= 9) {
      setPhone(value);
    }
  };

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  const { refetch } = useUserData();
  const access_token = Cookies.get("access_token");

  const updateCustomerEmail = async (value) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/customers/updateCustomer/${userData.id}?accessToken=${access_token}`,
        {
          email: email,
        }
      );
      console.log("Customer updated successfully!");
      refetch();
      dispatch(setAPopUpActive(false));
    } catch (error) {
      console.error("Error updating customer:", error);
    }
  };

  const updateCustomerPhone = async (value) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/customers/updateCustomer/${userData.id}?accessToken=${access_token}`,
        {
          phone: phone,
        }
      );
      console.log("Customer updated successfully!");
      refetch();
      dispatch(setAPopUpActive(false));
    } catch (error) {
      console.error("Error updating customer:", error);
    }
  };

  return (
    <div className="account-ss-pop-up">
      <div className="pop-wrap">
        <div className="top">
          <div className="title">{title}</div>
          <BtnCircular
            size={"sm"}
            mainColor={"white"}
            svg={<CloseIcon />}
            disabled={false}
            mode={"active"}
            onClick={() => dispatch(setAPopUpActive(false))}
          />
        </div>

        <div className="middle">
          <div className="msg">{msg}</div>
          {userData?.registered && userData?.email !== userData?.registered && (
            <InputGz
              type={"text"}
              value={email}
              setter={setEmail}
              label={Returntext(contentManagementData, "email", lang)}
              nid={"email"}
              disabled={false}
            />
          )}
          {userData?.registered && userData?.phone !== userData?.registered && (
            <InputGz
              type={"text"}
              value={phone}
              setter={handlePhoneChange}
              label={`${Returntext(
                contentManagementData,
                "phone",
                lang
              )} (+995)`}
              nid={"phone"}
              disabled={false}
            />
          )}
        </div>

        <div className="bottom">
          <BtnStandardIconText
            mode=""
            txt={Returntext(contentManagementData, "cancel", lang)}
            disabled={false}
            size={"standard"}
            icon={false}
            mainColor={"white"}
            onClick={() => dispatch(setAPopUpActive(false))}
          />

          <BtnStandardIconText
            mode=""
            txt={Returntext(contentManagementData, "confirm", lang)}
            disabled={false}
            size={"standard"}
            icon={false}
            mainColor={"green"}
            onClick={() => {
              if (
                userData?.registered &&
                userData?.phone !== userData?.registered
              ) {
                updateCustomerPhone();
              } else {
                updateCustomerEmail();
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AccoundSecondaryEditPP;
