import axios from "axios";
import Cookies from "js-cookie";

const refreshAccessToken = async () => {
  try {
    const refreshToken = Cookies.get("refresh_token");
    const refreshResponse = await axios.post(
      `${process.env.REACT_APP_AUTH_URL}/v1/auth/token/refresh`,
      {
        refresh_token: refreshToken,
      },
      {
        headers: {
          "x-public-key": `${process.env.REACT_APP_PUBLIC_KEY}`,
          "Content-Type": "application/json",
        },
      }
    );

    // Update access token
    const newAccessToken = refreshResponse.data.access_token;
    const sevenDaysFromNow = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000); // 7 days in milliseconds

    // Set the access_token cookie with expiration date
    // Set the access_token cookie with expiration date
    Cookies.set("access_token", newAccessToken, {
      expires: sevenDaysFromNow,
      secure: true,
      sameSite: "Strict",
    });
  } catch (refreshErr) {
    console.log("Failed to refresh token:", refreshErr);
    // Logout user
    localStorage.setItem("user", null);
    Cookies.remove("access_token");
    Cookies.remove("refresh_token");
    return null;
  }
};

export default refreshAccessToken;
