import { useNavigate, useLocation } from "react-router-dom";
import "./navbarLanding.scss";
import { useState, useEffect } from "react";
import { Returntext } from "../../../pages/Landings/common/returnText";
import { useContent, useLanguages } from "../../../hooks/content";

const NavbarLanding = () => {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  const pages = [
    {
      label: Returntext(contentManagementData, "navbar_home", lang),
      value: "home",
    },
    {
      label: Returntext(contentManagementData, "navbar_prices", lang),
      value: "prices",
    },
    {
      label: Returntext(contentManagementData, "navbar_delivery", lang),
      value: "delivery",
    },
    {
      label: Returntext(contentManagementData, "navbar_aboutus", lang),
      value: "about",
    },
  ];
  const [activePage, setActivePage] = useState(pages[0]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const currentPageValue = location.pathname.split("/")[2];
    const currentPage = pages.find((page) => page.value === currentPageValue);
    if (currentPage) {
      setActivePage(currentPage);
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const handlePageClick = (page) => {
    setActivePage((prevPage) => {
      if (prevPage.value !== page.value) {
        navigate(`/landing/${page.value}`);
      }
      return page;
    });
  };

  return (
    <div className="navbar-landing">
      {pages.map((page, index) => (
        <div
          key={index}
          className={`nav-item ${
            page.value === activePage.value ? "active" : ""
          }`}
          onClick={() => handlePageClick(page)}
        >
          {page.label}
        </div>
      ))}
    </div>
  );
};

export default NavbarLanding;
