import axios from "axios";
import Cookies from "js-cookie";
import { useMutation } from "react-query";

const login = async ({ username, password }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_AUTH_URL}/v1/auth/login`,
      { username, password },
      {
        headers: {
          "x-public-key": `${process.env.REACT_APP_PUBLIC_KEY}`,
          "Content-Type": "application/json",
        },
      }
    );

    const { access_token, refresh_token } = response.data;
    const sevenDaysFromNow = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);
    // Set the access_token cookie with expiration date
    Cookies.set("access_token", access_token, {
      expires: sevenDaysFromNow,
      secure: true,
      sameSite: "Strict",
    });

    // Set the refresh_token cookie with expiration date
    Cookies.set("refresh_token", refresh_token, {
      expires: sevenDaysFromNow,
      secure: true,
      sameSite: "Strict",
    });

    return response;
  } catch (err) {
    return err.response;
  }
};

const logout = async () => {
  const access_token = Cookies.get("access_token");
  Cookies.remove("access_token");
  Cookies.remove("refresh_token");
  localStorage.removeItem("user");
  await axios.post(`${process.env.REACT_APP_AUTH_URL}/v1/auth/logout`, {
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
      "x-public-key": process.env.REACT_APP_PUBLIC_KEY,
    },
  });
};

const extractToken = (token) => {
  try {
    const tokenParts = token.split(".");
    const decodedPayload = JSON.parse(atob(tokenParts[1]));
    return decodedPayload;
  } catch (error) {
    console.error("Invalid token format");
    return null;
  }
};

const useLoginMutation = () => {
  return useMutation(login, {
    onSuccess: (data) => {
      if (data.data && data.data.access_token && data.data.refresh_token) {
        window.location.href = "/orders/AWAITING";
      }
    },
  });
};

const useLogoutMutation = () => {
  return useMutation(logout);
};

const sendOTPEmail = async (email) => {
  return axios.post(
    `${process.env.REACT_APP_AUTH_URL}/v1/auth/otp/email/send`,
    { email },
    {
      headers: {
        "x-public-key": `${process.env.REACT_APP_PUBLIC_KEY}`,
        "Content-Type": "application/json",
      },
    }
  );
};

const sendOTPPhone = async (phone) => {
  return axios.post(
    `${process.env.REACT_APP_AUTH_URL}/v1/auth/otp/phone/send`,
    { phone: `+995${phone}` },
    {
      headers: {
        "x-public-key": `${process.env.REACT_APP_PUBLIC_KEY}`,
        "Content-Type": "application/json",
      },
    }
  );
};

const verifyOTPEmail = async ({ email, code }) => {
  const response = await axios.post(
    `${process.env.REACT_APP_AUTH_URL}/v1/auth/otp/email/check`,
    { email: email, code: code },
    {
      headers: {
        "x-public-key": `${process.env.REACT_APP_PUBLIC_KEY}`,
        "Content-Type": "application/json",
      },
    }
  );

  const { access_token, refresh_token } = response.data;
  const sevenDaysFromNow = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000); // 7 days in milliseconds
  // Set the access_token cookie with expiration date
  // Set the access_token cookie with expiration date
  Cookies.set("access_token", access_token, {
    expires: sevenDaysFromNow,
    secure: true,
    sameSite: "Strict",
  });

  // Set the refresh_token cookie with expiration date
  Cookies.set("refresh_token", refresh_token, {
    expires: sevenDaysFromNow,
    secure: true,
    sameSite: "Strict",
  });
  return response.data;
};

const verifyOTPhone = async ({ phone, code }) => {
  const response = await axios.post(
    `${process.env.REACT_APP_AUTH_URL}/v1/auth/otp/phone/check`,
    { phone: phone, code: code },
    {
      headers: {
        "x-public-key": `${process.env.REACT_APP_PUBLIC_KEY}`,
        "Content-Type": "application/json",
      },
    }
  );

  const { access_token, refresh_token } = response.data;
  const sevenDaysFromNow = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000); // 7 days in milliseconds

  // Set the access_token cookie with expiration date
  Cookies.set("access_token", access_token, {
    expires: sevenDaysFromNow,
    secure: true,
    sameSite: "Strict",
  });

  // Set the refresh_token cookie with expiration date
  Cookies.set("refresh_token", refresh_token, {
    expires: sevenDaysFromNow,
    secure: true,
    sameSite: "Strict",
  });
  return response.data;
};

const addPassword = async ({ password, access_token, email, phone }) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_AUTH_URL}/v1/auth/password/set`,
      { password },
      {
        headers: {
          "x-public-key": `${process.env.REACT_APP_PUBLIC_KEY}`,
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );

    return { success: true };
  } catch (error) {
    console.error("Error setting password:", error);
    throw error;
  }
};

const useAddPasswordMutation = () => {
  const mutation = useMutation(addPassword);

  return {
    mutation,
    createCustomer: async ({ email, phone, access_token, customerType }) => {
      try {
        const customerResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}/customers?accessToken=${access_token}`,
          {
            email,
            phone,
            customerType: customerType,
          }
        );

        console.log("Customer data sent:", customerResponse.data);
        window.location.href = "/onboarding";
      } catch (error) {
        console.error("Error creating customer:", error);
        throw error;
      }
    },
  };
};

const resetPassword = async ({
  access_token,
  currentPassword,
  newPassword,
}) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_AUTH_URL}/v1/auth/password/update`,
      { currentPassword, newPassword },
      {
        headers: {
          "x-public-key": `${process.env.REACT_APP_PUBLIC_KEY}`,
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );

    return { success: true };
  } catch (error) {
    console.error("Error resetting password:", error);
    throw error;
  }
};

const resetPasswordMutation = async ({
  access_token,
  currentPassword,
  newPassword,
}) => {
  try {
    await resetPassword({ access_token, currentPassword, newPassword });
    return { success: true };
  } catch (error) {
    console.error("Error resetting password:", error);
    throw error;
  }
};

const useResetPasswordMutation = () => {
  return useMutation(resetPasswordMutation);
};

const useSendOTPEmailMutation = () => {
  return useMutation(sendOTPEmail);
};

const useSendOTPPhoneMutation = () => {
  return useMutation(sendOTPPhone);
};

const useVerifyOTPEmailMutation = () => {
  return useMutation(verifyOTPEmail);
};

const useVerifyOTPPhoneMutation = () => {
  return useMutation(verifyOTPhone);
};

export {
  useLoginMutation,
  useLogoutMutation,
  useSendOTPEmailMutation,
  useSendOTPPhoneMutation,
  useVerifyOTPEmailMutation,
  useVerifyOTPPhoneMutation,
  addPassword,
  useAddPasswordMutation,
  useResetPasswordMutation,
  extractToken,
};
