import React from "react";
import "./customBodyS2.scss";
import StepHeader from "../StepHeader/StepHeader";
import CustomPaymentBox from "../CustomPaymentBox/CustomPaymentBox";
import Nusxa from "../Nusxa.jsx/Nusxa";

const CustomBodyS2 = ({
  step,
  main,
  sub,
  setTipsDrawerActive,
  activeOrder,
  files,
  setFiles,
  setActiveOrder,
}) => {
  return (
    <div className="custom-body-s2">
      <StepHeader
        step={step}
        main={main}
        sub={sub}
        onClick={() => setTipsDrawerActive(true)}
      />

      <CustomPaymentBox
        files={files}
        setFiles={setFiles}
        activeOrder={activeOrder}
        setActiveOrder={setActiveOrder}
      />
      <Nusxa activeOrder={activeOrder} />
    </div>
  );
};

export default CustomBodyS2;
