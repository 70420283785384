import React, { useEffect, useState } from "react";
import "./onboardFormBody.scss";
import InputGz from "../../../ds/InputGz/InputGz";
import { useSnackbar } from "notistack";
import BtnStandardIconText from "../../../ds/BtnStandardIconText/BtnStandardIconText";
import { useNavigate } from "react-router-dom";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";
import Cookies from "js-cookie";

const OnboardFormBody = ({
  stage,
  setSdaVerified,
  fistName,
  fistNameError,
  lasName,
  lasNameError,
  firstNameG,
  firstNameGError,
  lasNameG,
  lasNameGError,
  email,
  companyName,
  companyNameError,
  companyId,
  idNum,
  idNumError,
  setFirstName,
  setLastName,
  setFirstNameG,
  setLastNameG,
  setEmail,
  setCompanyName,
  setCompanyId,
  setIdNum,
  setStage,
  phone,
  setPhone,
  activeMode,
  setActiveMode,
  phoneError,
  emailError,
}) => {
  // const [userType, setUserType] = useState("personal");
  const [companyError, setCompanyError] = useState(false);

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const handlePhoneChange = (value) => {
    const numericRegex = /^[0-9]*$/;
    if (numericRegex.test(value) || value === "") {
      setPhone(value);
    }
  };

  const handleNameChange = (userInput) => {
    const regex = /^[a-zA-Z]*$/;
    if (regex.test(userInput)) {
      setFirstName(userInput);
    }
  };

  const handleLastNameChange = (userInput) => {
    const regex = /^[a-zA-Z]*$/;
    if (regex.test(userInput)) {
      setLastName(userInput);
    }
  };

  const handleNameChangeG = (userInput) => {
    const regex = /^[ა-ჰ]*$/;
    if (regex.test(userInput)) {
      setFirstNameG(userInput);
    }
  };

  const handleLastNameChangeG = (userInput) => {
    const regex = /^[ა-ჰ]*$/;
    if (regex.test(userInput)) {
      setLastNameG(userInput);
    }
  };
  const access_token = Cookies.get("access_token");

  useEffect(() => {
    const getNameFromTIN = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/soap/get-name?tin=${idNum}&accessToken=${access_token}`
        );
        const data = await response.json();

        const cleanedText = data.value.replace(/[.\s]/g, "");

        const nameL = cleanedText.charAt(0);
        const surnameL = cleanedText.charAt(1);

        if (response.status === 200) {
          if (nameL === firstNameG[0] && surnameL === lasNameG[0]) {
            setSdaVerified(true);
          } else {
            setSdaVerified(false);
            enqueueSnackbar(
              Returntext(contentManagementData, "ErrorMessages-signin6", lang),
              {
                variant: "error",
              }
            );
          }
        } else {
          setSdaVerified(false);
          enqueueSnackbar(
            Returntext(contentManagementData, "ErrorMessages-signin6", lang),
            {
              variant: "error",
            }
          );
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };

    if (activeMode === "resident") {
      if (idNum.length === 11) {
        getNameFromTIN();
      } else {
        setSdaVerified(false);
      }
    } else {
      setSdaVerified(true);
    }
  }, [idNum, firstNameG, lasNameG, activeMode]);

  useEffect(() => {
    setCompanyName("");
    const getNameFromTINB = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/soap/get-name?tin=${companyId}&accessToken=${access_token}`
        );
        const data = await response.json();

        console.log(data);

        if (data.value !== "null") {
          setSdaVerified(true);
          setCompanyName(data.value);
          setCompanyError(false);
        } else {
          setSdaVerified(false);
          setCompanyName("");
          setCompanyError(true);
          enqueueSnackbar(
            "Could Not Find Company with this identification number",
            {
              variant: "error",
            }
          );
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };

    if (companyId.length > 8) {
      getNameFromTINB();
    } else {
      setSdaVerified(false);
    }
  }, [companyId]);

  // const { data: userData } = useUserData();
  const userData = JSON.parse(localStorage.getItem("user"));

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  const cleanUp = () => {
    setFirstName("");
    setLastName("");
    setLastNameG("");
    setFirstNameG("");
    setIdNum("");
    setPhone("");
    setEmail("");
  };
  return (
    <div className="onboard-form-body">
      {stage === 0 && userData.customerType === "PERSONAL" && (
        <div style={{ display: "flex", gap: "16px" }}>
          <BtnStandardIconText
            mode=""
            txt={Returntext(contentManagementData, "resident", lang)}
            disabled={false}
            size={"md"}
            icon={false}
            mainColor={activeMode === "resident" ? "black" : "white"}
            onClick={() => {
              cleanUp();
              setActiveMode("resident");
            }}
            style={{ width: "50%" }}
          />
          <BtnStandardIconText
            mode=""
            txt={Returntext(contentManagementData, "nonresident", lang)}
            disabled={false}
            size={"md"}
            icon={false}
            mainColor={activeMode === "non-resident" ? "black" : "white"}
            onClick={() => {
              cleanUp();
              setActiveMode("non-resident");
            }}
            style={{ width: "50%" }}
          />
        </div>
      )}

      {stage === 0 && (
        <>
          <InputGz
            type={"text"}
            value={fistName}
            setter={handleNameChange}
            label={Returntext(contentManagementData, "firstName", lang)}
            nid={"Firstname"}
            disabled={false}
            errorM={fistNameError}
          />
          <InputGz
            type={"text"}
            value={lasName}
            setter={handleLastNameChange}
            label={Returntext(contentManagementData, "LastName", lang)}
            nid={"Lastname"}
            disabled={false}
            errorM={lasNameError}
          />
          {activeMode !== "non-resident" && (
            <>
              {" "}
              <InputGz
                type={"text"}
                value={firstNameG}
                setter={handleNameChangeG}
                label={Returntext(contentManagementData, "FirstNameG", lang)}
                nid={"firstNameG"}
                disabled={false}
                errorM={firstNameGError}
              />
              <InputGz
                type={"text"}
                value={lasNameG}
                setter={handleLastNameChangeG}
                label={Returntext(contentManagementData, "LastNameG", lang)}
                nid={"lasNameG"}
                disabled={false}
                errorM={lasNameGError}
              />
            </>
          )}

          {userData.customerType === "PERSONAL" && (
            <>
              <InputGz
                type={"text"}
                value={idNum}
                setter={setIdNum}
                label={Returntext(contentManagementData, "ID", lang)}
                nid={"idNumber"}
                disabled={false}
                errorM={idNumError}
              />
              {userData.email === "" && (
                <InputGz
                  type={"text"}
                  value={email}
                  setter={setEmail}
                  label={Returntext(contentManagementData, "email", lang)}
                  nid={"email"}
                  disabled={false}
                  errorM={false}
                />
              )}

              {userData.phone === "" && (
                <InputGz
                  type={"text"}
                  value={phone}
                  setter={handlePhoneChange}
                  label={`${Returntext(
                    contentManagementData,
                    "phone",
                    lang
                  )} (+995)`}
                  nid={"phone"}
                  disabled={false}
                  errorM={phoneError}
                />
              )}
            </>
          )}
          {userData.customerType === "BUSINESS" && (
            <>
              <span className="b-break">
                {Returntext(contentManagementData, "email", lang)}
              </span>
              <InputGz
                type={"text"}
                value={companyId}
                setter={setCompanyId}
                label={Returntext(contentManagementData, "CID", lang)}
                nid={"CompanyID"}
                disabled={false}
                errorM={companyError}
              />
              <InputGz
                type={"text"}
                value={companyName}
                setter={setCompanyName}
                label={Returntext(contentManagementData, "CompanyName", lang)}
                nid={"companyName"}
                disabled={true}
                errorM={companyNameError}
              />
              {userData.email === "" && (
                <InputGz
                  type={"text"}
                  value={email}
                  setter={setEmail}
                  label={Returntext(contentManagementData, "email", lang)}
                  nid={"email"}
                  disabled={false}
                  errorM={emailError}
                />
              )}

              {userData.phone === "" && (
                <InputGz
                  type={"text"}
                  value={phone}
                  setter={handlePhoneChange}
                  label={`${Returntext(
                    contentManagementData,
                    "phone",
                    lang
                  )} (+995)`}
                  nid={"phone"}
                  disabled={false}
                  errorM={false}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default OnboardFormBody;
