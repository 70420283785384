import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import Sidebar from "../ds/Sidebar/Sidebar";
import "./onboard.scss";
import Header from "../components/organisms/Header/Header";

const OnboardingLayout = ({ web, mob }) => {
  return (
    <div className="main-layout-onboard">
      <BrowserView>
        <Sidebar />
        <div className="main-wrap-obs"> {web}</div>
      </BrowserView>
      <MobileView>
        <Header />
        <div className="main-wrap-obs">{mob}</div>
      </MobileView>
    </div>
  );
};

export default OnboardingLayout;
