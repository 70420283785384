import React, { useEffect, useState } from "react";
import "./timeSlotBox.scss";
import BtnStandardIconText from "../../../ds/BtnStandardIconText/BtnStandardIconText";
import axios from "axios";
import Cookies from "js-cookie";
import { useTimeSlot } from "../../../hooks/ts";
import { ReactComponent as SunIcon } from "../../../icons/sun-md.svg";

const formatDate = (date) => {
  const options = { day: "numeric", month: "long", timeZone: "Asia/Tbilisi" };
  const formattedDate = new Date(date).toLocaleDateString("en-US", options);
  const parts = formattedDate.split(" ");
  return `${parts[0]} ${parts[1]}`;
};

const TimeSlotBox = ({ ts, setTs }) => {
  const [dt, setDt] = useState("");

  const today = new Date(dt);
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const { timeSlots } = useTimeSlot();
  const access_token = Cookies.get("access_token");

  useEffect(() => {
    const checkTime = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/district/date?accessToken=${access_token}`
        );

        setDt(res.data);
      } catch (err) {
        console.log(err);
      }
    };

    checkTime();
  }, []);
  return (
    <div className="time-slot-box">
      {timeSlots?.today?.length > 0 && (
        <div className="time-item">
          <div className="title">{formatDate(today)}</div>
          <div className="ts-items">
            {timeSlots?.today?.map((item, i) => (
              <BtnStandardIconText
                key={i}
                mode={""}
                txt={item}
                disabled={false}
                size={"sm"}
                icon={true}
                iconPosition={"right"}
                svg={<SunIcon />} // Use the SVG component here
                mainColor={ts === item ? "green" : "grayBorder"}
                onClick={() => {
                  setTs(item);
                }}
              />
            ))}
          </div>
        </div>
      )}

      {timeSlots?.tomorrow?.length > 0 && (
        <div className="time-item">
          <div className="title">{formatDate(tomorrow)}</div>
          <div className="ts-items">
            {timeSlots?.tomorrow?.map((item, i) => (
              <BtnStandardIconText
                key={i}
                mode={""}
                txt={item}
                disabled={false}
                size={"sm"}
                icon={true}
                iconPosition={"right"}
                svg={<SunIcon />} // Use the SVG component here
                mainColor={ts === item ? "green" : "grayBorder"}
                onClick={() => {
                  setTs(item);
                }}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default TimeSlotBox;
