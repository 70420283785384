import React, { useState } from "react";
import "./signUpFormBody.scss";
import BreakOr from "../../atoms/BreakOr/BreakOr";
import countries from "./countries";
import InputGz from "../../../ds/InputGz/InputGz";
import BtnStandardIconText from "../../../ds/BtnStandardIconText/BtnStandardIconText";
import {
  useSendOTPEmailMutation,
  useSendOTPPhoneMutation,
} from "../../../hooks/auth";
import Autocomplete from "../../../ds/Autocomplete/Autocomplete";
import { useSnackbar } from "notistack";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";
import PhoneIcon from "../../../icons/phone-icon-btn.png"; // Import the PNG file

const SignUpFormBody = ({
  continueClick,
  email,
  setEmail,
  sMethod,
  SetSMethod,
  phone,
  setPhone,
  activeMode,
  setActiveMode,
  cSelectActive,
  setCSelectActive,
  countryData,
  setCountryData,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);

  const sendOTPEmailMutation = useSendOTPEmailMutation();
  const sendOTPPHoneMutation = useSendOTPPhoneMutation();

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  const handleSendEmailOTP = async () => {
    if (!email) {
      setEmailError(true);
      enqueueSnackbar("Email can't be empty!", {
        variant: "error",
      });
      return;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError(true);
      enqueueSnackbar("Invalid email format!", {
        variant: "error",
      });
      return;
    }
    setEmailError(false);

    try {
      const response = await sendOTPEmailMutation.mutateAsync(email);
      if (response.data.accountExists) {
        enqueueSnackbar(
          Returntext(contentManagementData, "ErrorMessages-signin3", lang),
          {
            variant: "error",
          }
        );
        setEmailError(true);
      } else {
        setEmailError(false);
        continueClick();
      }
    } catch (err) {
      enqueueSnackbar("Error sending OTP!", {
        variant: "error",
      });
    }
  };

  const handleSendPhoneOTP = async () => {
    if (!phone || !countryData) {
      setPhoneError(true);
      enqueueSnackbar("Phone can't be empty!", {
        variant: "error",
      });
      return;
    }
    setPhoneError(false);
    try {
      const response = await sendOTPPHoneMutation.mutateAsync(phone);
      console.log(response);
      if (response.data.accountExists) {
        setPhoneError(true);
        enqueueSnackbar(
          Returntext(contentManagementData, "ErrorMessages-signin3", lang),
          {
            variant: "error",
          }
        );
      } else {
        setPhoneError(false);
        continueClick();
      }
    } catch (err) {
      enqueueSnackbar("Error sending OTP!", {
        variant: "error",
      });
    }
  };

  const handlePhoneInputChange = (email) => {
    const input = email.replace(/\D/g, ""); // Remove non-numeric characters
    setPhone(input);
  };

  return (
    <div className="sign-up-form-body">
      <div className="mode-switcher">
        <BtnStandardIconText
          mode=""
          txt={Returntext(contentManagementData, "personal", lang)}
          disabled={false}
          size={"md"}
          icon={false}
          mainColor={activeMode === "personal" ? "black" : "white"}
          onClick={() => {
            setActiveMode("personal");
          }}
          style={{ width: "50%" }}
        />

        <BtnStandardIconText
          mode=""
          txt={Returntext(contentManagementData, "business", lang)}
          disabled={false}
          size={"md"}
          icon={false}
          mainColor={activeMode === "business" ? "black" : "white"}
          onClick={() => {
            setActiveMode("business");
          }}
          style={{ width: "50%" }}
        />
      </div>

      <div className="sign-in-form-body-wrap">
        <div className="inputs-wrap">
          {sMethod === "email" && (
            <>
              <InputGz
                type={"text"}
                value={email}
                setter={setEmail}
                label={Returntext(contentManagementData, "email", lang)}
                nid={"email"}
                disabled={false}
                errorM={emailError}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleSendEmailOTP();
                  }
                }}
              />
            </>
          )}
          {sMethod === "phone" && (
            <div className="p-inputs-wrap">
              <Autocomplete
                type={"text"}
                value={countryData}
                setter={setCountryData}
                label={Returntext(
                  contentManagementData,
                  "Country/Region",
                  lang
                )}
                nid={"Country/Region"}
                disabled={false}
                errorM={false}
                style={
                  !cSelectActive
                    ? {
                        borderBottomLeftRadius: "0",
                        borderBottomRightRadius: "0",
                      }
                    : {}
                }
                sActive={cSelectActive}
                setSActive={setCSelectActive}
                data={countries}
              />

              {!cSelectActive && (
                <InputGz
                  type={"text"}
                  value={phone}
                  setter={handlePhoneInputChange}
                  label={Returntext(contentManagementData, "phone", lang)}
                  nid={"Phone number"}
                  disabled={false}
                  errorM={phoneError}
                  style={{
                    borderTopRightRadius: "0",
                    borderTopLeftRadius: "0",
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleSendPhoneOTP();
                    }
                  }}
                />
              )}
            </div>
          )}
          <BtnStandardIconText
            mode=""
            txt={Returntext(contentManagementData, "continue", lang)}
            disabled={false}
            size={"standard"}
            icon={true}
            iconPosition={"right"}
            mainColor={"green"}
            onClick={() => {
              sMethod === "email" ? handleSendEmailOTP() : handleSendPhoneOTP();
            }}
          />
        </div>

        <BreakOr />

        <div className="additional-methods">
          <BtnStandardIconText
            mode=""
            txt={
              sMethod === "email"
                ? Returntext(
                    contentManagementData,
                    "signUpcontinueWithPhone",
                    lang
                  )
                : Returntext(
                    contentManagementData,
                    "signUpcontinueWithEmail",
                    lang
                  )
            }
            disabled={false}
            size={"standard"}
            icon={true}
            iconPosition={"left"}
            mainColor={"grayBorder"}
            svg={<img src={PhoneIcon} alt="Phone Icon" />} // Use the PNG file here
            onClick={() => {
              sMethod === "email" ? SetSMethod("phone") : SetSMethod("email");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SignUpFormBody;
