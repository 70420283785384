import React from "react";
import "./btnStandardIconText.scss";

const BtnStandardIconText = ({
  mode,
  txt,
  svg,
  svgDisabled,
  disabled,
  mainColor,
  icon,
  size,
  iconPosition,
  onClick,
  style,
}) => {
  return (
    <button
      className={
        !disabled
          ? `btn-standard-icon-text ${mainColor} ${size} ${mode}`
          : `btn-standard-icon-text ${mainColor} ${size} disabled`
      }
      onClick={onClick}
      style={style}
      disabled={disabled}
    >
      {icon && iconPosition === "left" && disabled ? svgDisabled : svg}
      <span className="txt">{txt}</span>
      {icon && iconPosition === "right" && (disabled ? svgDisabled : svg)}
    </button>
  );
};

export default BtnStandardIconText;
