import React from "react";
import "./wallet.scss";
import AccountSettingsTitle from "../../components/atoms/AccountSettingsTitle/AccountSettingsTitle";
import WalletBody from "../../components/organisms/WalletBody/WalletBody";
import { useAddCard } from "../../hooks/payments";
import { useContent, useLanguages } from "../../hooks/content";
import { Returntext } from "../Landings/common/returnText";

const Wallet = ({
  setPDrawerActive,
  setOrderId,
  setShowModal,
  deleteItem,
  setDeleteItem,
  activeItem,
  setActiveItem,
}) => {
  const { handleAddCard } = useAddCard();

  const data = {
    language: "KA",
    theme: "light",
    currency: "GEL",
    paymentProcessorName: "BOG",
  };
  const saveCard = async () => {
    setShowModal(true);
    try {
      const returnData = await handleAddCard(data);
      setOrderId(returnData.orderId);
    } catch (error) {
      console.error("Error adding card:", error);
    }
  };

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  return (
    <div className="wallet">
      <div className="main-content">
        <AccountSettingsTitle
          main={Returntext(contentManagementData, "title_main", lang)}
          sub={Returntext(contentManagementData, "title_sub", lang)}
        />
        <WalletBody
          setShowModal={setShowModal}
          setActiveItem={setActiveItem}
          activeItem={activeItem}
          deleteItem={deleteItem}
          setDeleteItem={setDeleteItem}
          saveCard={saveCard}
          setPDrawerActive={setPDrawerActive}
        />
      </div>
    </div>
  );
};

export default Wallet;
