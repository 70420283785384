import { useQuery, useQueryClient } from "react-query";
import axios from "axios";
import Cookies from "js-cookie";
import refreshAccessToken from "./refresh";

const fetchOrderPrices = async (orderData) => {
  try {
    const access_token = Cookies.get("access_token");
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/orders/getOrderPrices?accessToken=${access_token}`,
      orderData
    );
    return response.data;
  } catch (err) {
    if (
      err.response &&
      (err.response.status === 401 || err.response.status === 403)
    ) {
      refreshAccessToken();
      return await fetchOrderPrices();
    } else {
      console.log("Error:", err);
      localStorage.setItem("user", null);
      Cookies.remove("access_token");
      Cookies.remove("refresh_token");
      return null;
    }
  }
};

const useOrderPrices = (orderData) => {
  const { data, isLoading, isError, error, refetch } = useQuery(
    ["orderPrices", orderData],
    () => fetchOrderPrices(orderData)
  );

  const refetchOrderPrices = () => {
    refetch();
  };

  return { data, isLoading, isError, error, refetchOrderPrices };
};

export default useOrderPrices;
