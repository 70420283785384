import React, { useEffect, useRef, useState } from "react";
import "./otpEmail.scss";
import {
  useSendOTPEmailMutation,
  useSendOTPPhoneMutation,
  useVerifyOTPEmailMutation,
  useVerifyOTPPhoneMutation,
} from "../../../hooks/auth";
import BtnStandardIconText from "../../../ds/BtnStandardIconText/BtnStandardIconText";
import { useSnackbar } from "notistack";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";
import Countdown from "react-countdown";

const OtpEmail = ({ continueClick, sMethod, fPhone, email, setCodeParent }) => {
  const [box1, setBox1] = useState("");
  const [box2, setBox2] = useState("");
  const [box3, setBox3] = useState("");
  const [box4, setBox4] = useState("");

  const phone = `+995${fPhone.toString()}`;

  const { mutateAsync: verifyPhoneOTP } = useVerifyOTPPhoneMutation();
  const { mutateAsync: verifyEmailOTP } = useVerifyOTPEmailMutation();

  const [boxError, setBoxError] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const sendOTPEmailMutation = useSendOTPEmailMutation();
  const sendOTPPHoneMutation = useSendOTPPhoneMutation();

  const handleSendEmailOTP = async () => {
    try {
      const response = await sendOTPEmailMutation.mutateAsync(email);
    } catch (err) {
      enqueueSnackbar("!", {
        variant: "error",
      });
    }
  };

  const handleSendPhoneOTP = async () => {
    try {
      const response = await sendOTPPHoneMutation.mutateAsync(phone);
    } catch (err) {
      enqueueSnackbar("!", {
        variant: "error",
      });
    }
  };

  const handleVerifyPhoneOTP = async () => {
    if (!box1 || !box2 || !box3 || !box4) {
      setBoxError(true);
      enqueueSnackbar(
        Returntext(contentManagementData, "ErrorMessages-signin4", lang),
        {
          variant: "error",
        }
      );
      return;
    }
    setBoxError(false);

    try {
      const code = `${box1}${box2}${box3}${box4}`;
      if (setCodeParent !== undefined) {
        setCodeParent(code);
      }

      await verifyPhoneOTP({ phone, code });
      continueClick();
    } catch (error) {
      setBoxError(true);
      enqueueSnackbar(
        Returntext(contentManagementData, "ErrorMessages-signin4", lang),
        {
          variant: "error",
        }
      );
    }
  };

  const handleVerifyEmailOTP = async () => {
    const code = `${box1}${box2}${box3}${box4}`;
    if (setCodeParent !== undefined) {
      setCodeParent(code);
      console.log("hi");
    }
    if (!email || !code) {
      enqueueSnackbar(
        Returntext(contentManagementData, "ErrorMessages-signin4", lang),
        {
          variant: "error",
        }
      );
      setBoxError(true);
      return;
    }

    setBoxError(false);

    try {
      await verifyEmailOTP({ email, code });
      continueClick();
      setBoxError(false);
    } catch (error) {
      setBoxError(true);
      enqueueSnackbar(
        Returntext(contentManagementData, "ErrorMessages-signin4", lang),
        {
          variant: "error",
        }
      );
    }
  };

  const box1Ref = useRef(null);
  const box2Ref = useRef(null);
  const box3Ref = useRef(null);
  const box4Ref = useRef(null);

  const handleInputChange = (value, setter, prevRef, nextRef) => {
    if (value === "") {
      setter(value);
      if (prevRef && prevRef.current) {
        prevRef.current.focus();
      }
    } else if (/^\d$/.test(value)) {
      setter(value);
      if (nextRef && nextRef.current) {
        nextRef.current.focus();
      }
    }
  };

  const handleInputKeyDown = (e, setter, prevRef, nextRef, currentRef) => {
    const value = e.target.value;
    if (value === "") {
      if (e.key === "Backspace" && prevRef && prevRef.current) {
        e.preventDefault();
        prevRef.current.focus();
      }
    } else if (/^[0-9]$/.test(e.key)) {
      e.preventDefault();
      setter(e.key);
      if (nextRef && nextRef.current) {
        nextRef.current.focus();
      }
    }
  };

  const handleInputPaste = (e, setters) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text/plain").slice(0, 4);
    const newValues = pastedData.split("").filter((char) => /^\d$/.test(char));
    setters.forEach((setter, index) => {
      setter(newValues[index] || "");
    });
    box4Ref.current.focus();
  };

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  const initialTimeLeft = 60;
  const [timeLeft, setTimeLeft] = useState(
    parseInt(localStorage.getItem("countdownTime")) || initialTimeLeft
  );
  const [isRunning, setIsRunning] = useState(false);
  const [isRun, setIsRun] = useState(false);

  useEffect(() => {
    if (isRunning && timeLeft > 0) {
      const countdownInterval = setInterval(() => {
        setTimeLeft((prevTimeLeft) => {
          const newTimeLeft = prevTimeLeft - 1;
          localStorage.setItem("countdownTime", newTimeLeft.toString());
          return newTimeLeft;
        });
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
    if (timeLeft < 1) {
      setIsRun(false);
    }
  }, [isRunning, timeLeft]);

  const handleClick = () => {
    if (!isRunning) {
      setIsRunning(true);
      setIsRun(true);
    } else {
      setTimeLeft(initialTimeLeft);
      setIsRunning(true);
      setIsRun(true);
      localStorage.setItem("countdownTime", initialTimeLeft.toString());
    }
  };

  // Convert seconds to minutes and seconds for display
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  return (
    <div className="otp-email">
      <div className="otp-input-wrap">
        <div className="box-wrap">
          <input
            type="text"
            className={`otp-box ${boxError && "error"}`}
            maxLength="1"
            value={box1}
            ref={box1Ref}
            onInput={(e) =>
              handleInputChange(e.target.value, setBox1, null, box2Ref)
            }
            onKeyDown={(e) =>
              handleInputKeyDown(e, setBox1, null, box2Ref, box1Ref)
            }
            onPaste={(e) =>
              handleInputPaste(e, [setBox1, setBox2, setBox3, setBox4])
            }
          />
          <input
            type="text"
            className={`otp-box ${boxError && "error"}`}
            maxLength="1"
            value={box2}
            ref={box2Ref}
            onInput={(e) =>
              handleInputChange(e.target.value, setBox2, box1Ref, box3Ref)
            }
            onKeyDown={(e) =>
              handleInputKeyDown(e, setBox2, box1Ref, box3Ref, box2Ref)
            }
          />
          <input
            type="text"
            className={`otp-box ${boxError && "error"}`}
            maxLength="1"
            value={box3}
            ref={box3Ref}
            onInput={(e) =>
              handleInputChange(e.target.value, setBox3, box2Ref, box4Ref)
            }
            onKeyDown={(e) =>
              handleInputKeyDown(e, setBox3, box2Ref, box4Ref, box3Ref)
            }
          />
          <input
            type="text"
            className={`otp-box ${boxError && "error"}`}
            maxLength="1"
            value={box4}
            ref={box4Ref}
            onInput={(e) =>
              handleInputChange(e.target.value, setBox4, box3Ref, null)
            }
            onKeyDown={(e) =>
              handleInputKeyDown(e, setBox4, box3Ref, null, box4Ref)
            }
          />
        </div>

        <div className="message">
          {Returntext(contentManagementData, "SignUpNotCode", lang)}
          <div className="link">
            {!isRun ? (
              <span
                onClick={() => {
                  sMethod === "email"
                    ? handleSendEmailOTP()
                    : handleSendPhoneOTP();
                  handleClick();
                }}
              >
                {Returntext(contentManagementData, "ResendCode", lang)}
              </span>
            ) : (
              <span>{`${minutes.toString().padStart(2, "0")}:${seconds
                .toString()
                .padStart(2, "0")}`}</span>
            )}
          </div>
        </div>
      </div>

      <div className="bottom-wrap">
        <BtnStandardIconText
          mode=""
          txt={Returntext(contentManagementData, "confirm", lang)}
          disabled={
            box1 !== "" && box2 !== "" && box3 !== "" && box4 !== ""
              ? false
              : true
          }
          size={"standard"}
          icon={false}
          mainColor={"green"}
          onClick={() => {
            email !== "" ? handleVerifyEmailOTP() : handleVerifyPhoneOTP();
          }}
        />
      </div>
    </div>
  );
};

export default OtpEmail;
