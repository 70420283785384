import React, { useEffect, useState } from "react";
import "./paymentDrawer.scss";
import BtnCircular from "../../../ds/BtnCircular/BtnCircular";
import PaymentNusxa from "../../molecules/PaymentNusxa/PaymentNusxa";
import SelectAddress from "../../molecules/SelectAddress/SelectAddress";
import PaymentSummary from "../../molecules/PaymentSummary/PaymentSummary";
import ListItem from "../../../ds/ListItem/ListItem";
import OrderTableLabel from "../../../ds/OrderTableLabel/OrderTableLabel";
import ChooseAddressBox from "../../molecules/ChooseAddressBox/ChooseAddressBox";
import axios from "axios";
import AddressDrawer from "../AddressDrawer/AddressDrawer";
import DeliveryDrawer from "../../molecules/DeliveryDrawer/DeliveryDrawer";
import Cookies from "js-cookie";
import { useCustomerAddresses } from "../../../hooks/address";
import { Returntext } from "../../../pages/Landings/common/returnText";
import { useContent, useLanguages } from "../../../hooks/content";
import { ReactComponent as CloseIcon } from "../../../icons/x.svg";
import { ReactComponent as HomeIcon } from "../../../icons/home.svg";
import { ReactComponent as WorkIcon } from "../../../icons/work.svg";
import { ReactComponent as ArrowRightIcon } from "../../../icons/arrow-r.svg";
import PayedNusxa from "../../molecules/PayedNusxa/PayedNusxa";
import useOrderPrices from "../../../hooks/prices";

const PayedDrawer = ({ cancelClick, activeOrder, setActiveOrder }) => {
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [paymentSuccess, setPaymentSucces] = useState(false);

  const [totalValue, setTotalValue] = useState(0);

  const [cadActive, setCadActive] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const access_token = Cookies.get("access_token");

  useEffect(() => {
    const fetchUnpaidOrders = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/transactions/${activeOrder?.payments[0]?.id}?accessToken=${access_token}`
        );
        setActiveOrder(
          response?.data?.orders.length > 0 ? response?.data?.orders[0] : []
        );
        setSelectedOrders(response?.data?.orders);
        setTotalValue(response.data.amount);
      } catch (error) {
        console.error("Error fetching unpaid orders:", error);
      }
    };

    fetchUnpaidOrders();
  }, []);

  const { customerAddresses } = useCustomerAddresses();

  const [address, setAddress] = useState(
    customerAddresses !== undefined && customerAddresses.length !== 0
      ? customerAddresses.find((address) => address.isDefault === true)
      : ""
  );

  const [addressDrawer, setAddressDrawer] = useState(false);

  const nextClick = () => {
    setPaymentSucces(true);
  };

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  return (
    <div className={paymentSuccess ? "" : "payment-drawer"}>
      {!paymentSuccess && (
        <>
          <div className="shadow" onClick={cancelClick}></div>
          <div className="drawer-wrap">
            <div className="header">
              <div className="left"></div>

              {Returntext(contentManagementData, "PT", lang)}

              <BtnCircular
                size={"sm"}
                mainColor={"white"}
                svg={<CloseIcon />} // Use the SVG component here
                disabled={false}
                mode={"active"}
                onClick={cancelClick}
              />
            </div>

            {selectedOrders?.length > 0 && activeOrder.length !== 0 && (
              <div className="body">
                <PayedNusxa
                  main={Returntext(contentManagementData, "PNT", lang)}
                  activeOrder={activeOrder}
                  type={"delivery"}
                />

                <PaymentSummary
                  nextClick={nextClick}
                  selectedOrders={selectedOrders}
                  totalValue={totalValue}
                  setActiveOrder={setActiveOrder}
                  activeOrder={activeOrder}
                  address={address}
                  cancelClick={cancelClick}
                />
              </div>
            )}
          </div>
          {addressDrawer && (
            <AddressDrawer
              cancelClick={() => setAddressDrawer(false)}
              setUserAddress={setAddress}
              style={{ zIndex: "999" }}
            />
          )}
        </>
      )}
      {paymentSuccess && (
        <DeliveryDrawer cancelClick={cancelClick} activeOrder={activeOrder} />
      )}
    </div>
  );
};

export default PayedDrawer;
