import React, { useEffect, useState } from "react";
import "./signInFormBody.scss";
import BreakOr from "../../atoms/BreakOr/BreakOr";
import InputGz from "../../../ds/InputGz/InputGz";
import BtnStandardIconText from "../../../ds/BtnStandardIconText/BtnStandardIconText";
import { useLoginMutation } from "../../../hooks/auth";
import { useSnackbar } from "notistack";
import countries from "./countries";
import Autocomplete from "../../../ds/Autocomplete/Autocomplete";
import { useNavigate } from "react-router-dom";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";
import { ReactComponent as PhoneIcon } from "../../../icons/phone.svg";

const SignInFormBody = ({
  email,
  setEmail,
  password,
  setPassword,
  phone,
  setPhone,
}) => {
  const [sMethod, SetSMethod] = useState("email");
  const [signInError, setSignInError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [countryData, setCountryData] = useState("Georgia +995");
  const [cSelectActive, setCSelectActive] = useState(false);

  const loginMutation = useLoginMutation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleLogin = () => {
    if (sMethod === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        setEmailError(true);
        enqueueSnackbar("Please enter a valid email address", {
          variant: "error",
        });
        return;
      }
    }

    const unique_name = sMethod === "email" ? email : `+995${phone}`;
    loginMutation
      .mutateAsync({ username: unique_name, password })
      .then((data) => {
        console.log(data.data);
        if (data.data.message === "ACCOUNT_NOT_EXIST") {
          setSignInError(false);
          setEmailError(true);
          enqueueSnackbar(
            Returntext(contentManagementData, "ErrorMessages-signin1", lang),
            {
              variant: "error",
            }
          );
        } else if (
          data.data.statusCode === 401 ||
          data.data.statusCode === 403
        ) {
          setEmailError(false);
          setSignInError(true);
          enqueueSnackbar(
            Returntext(contentManagementData, "ErrorMessages-signin2", lang),
            {
              variant: "error",
            }
          );
        } else {
          setEmailError(false);
          setSignInError(false);
        }
      });
  };

  const handleEnterKey = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  const handlePhoneChange = (value) => {
    const numericRegex = /^[0-9]*$/;
    if (numericRegex.test(value) || value === "") {
      setPhone(value);
    }
  };

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  useEffect(() => {
    setPhone("");
    setEmail("");
    setPassword("");
    setSignInError(false);
  }, [sMethod]);

  return (
    <div className="sign-in-form-body">
      <div className="sign-in-form-body-wrap">
        <div className="inputs-wrap">
          {sMethod === "email" && (
            <InputGz
              type={"text"}
              value={email}
              setter={setEmail}
              label={Returntext(contentManagementData, "email", lang)}
              nid={"email"}
              disabled={false}
              errorM={emailError}
              onKeyDown={handleEnterKey}
            />
          )}

          {sMethod === "phone" && (
            <div className="p-inputs-wrap">
              <Autocomplete
                type={"text"}
                value={countryData}
                setter={setCountryData}
                label={Returntext(contentManagementData, "CountryRegion", lang)}
                nid={"Country/Region"}
                disabled={false}
                errorM={false}
                style={
                  !cSelectActive
                    ? {
                        borderBottomLeftRadius: "0",
                        borderBottomRightRadius: "0",
                      }
                    : {}
                }
                sActive={cSelectActive}
                setSActive={setCSelectActive}
                data={countries}
              />

              {!cSelectActive && (
                <InputGz
                  type={"text"}
                  value={phone}
                  setter={handlePhoneChange}
                  label={Returntext(contentManagementData, "phone", lang)}
                  nid={"Phone number"}
                  disabled={false}
                  errorM={signInError}
                  style={{
                    borderTopRightRadius: "0",
                    borderTopLeftRadius: "0",
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleLogin();
                    }
                  }}
                />
              )}
            </div>
          )}

          <InputGz
            type={"password"}
            value={password}
            setter={setPassword}
            label={Returntext(contentManagementData, "password", lang)}
            nid={"password"}
            disabled={false}
            errorM={signInError}
            onKeyDown={handleEnterKey}
          />

          <div
            onClick={() => navigate(`/password-reset/${sMethod}`)}
            className="link"
          >
            {Returntext(contentManagementData, "forgetPassword", lang)}{" "}
          </div>

          <BtnStandardIconText
            mode=""
            txt={Returntext(contentManagementData, "continue", lang)}
            disabled={false}
            size={"standard"}
            icon={false}
            iconPosition={"right"}
            mainColor={"green"}
            onClick={handleLogin}
          />
        </div>

        <BreakOr />

        <div className="additional-methods">
          <BtnStandardIconText
            mode=""
            txt={
              sMethod === "email"
                ? Returntext(contentManagementData, "continueWithPhone", lang)
                : Returntext(contentManagementData, "continueWithEmail", lang)
            }
            disabled={false}
            size={"standard"}
            icon={true}
            iconPosition={"left"}
            mainColor={"grayBorder"}
            svg={<PhoneIcon />}
            svgDisabled={<PhoneIcon />}
            onClick={() => {
              sMethod === "email" ? SetSMethod("phone") : SetSMethod("email");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SignInFormBody;
