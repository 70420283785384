import React from "react";
import "./actionTabBtnCount.scss";

const ActionTabBtnCount = ({ count, active, inactive }) => {
  return (
    <div
      className={`action-tab-btn-count ${
        !active && count > 0 && !inactive && "gr"
      }`}
    >
      {count}
    </div>
  );
};

export default ActionTabBtnCount;
