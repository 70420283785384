import React from "react";
import "./sidebarIcons.scss";
import { useNavigate } from "react-router-dom";

const SidebarIcons = ({ headIcons }) => {
  const navigate = useNavigate();
  return (
    <div className="sidebar-icons">
      <div className="left-icon" onClick={() => navigate("/orders/AWAITING")}>
        {headIcons.gzLogo}
      </div>
    </div>
  );
};

export default SidebarIcons;
