import React from "react";
import "./shippingAddressBody.scss";
import ShipmentInformation from "../../molecules/ShipmentInformation/ShipmentInformation";
import ShipmentMaterials from "../../molecules/ShipmentMaterials/ShipmentMaterials";

const ShippingAddressBody = () => {
  return (
    <div className="shipping-address-body-c">
      <ShipmentInformation />
      <ShipmentMaterials />
    </div>
  );
};

export default ShippingAddressBody;
