import React from "react";
import "./nameBarMobile.scss";
import { useNavigate } from "react-router-dom";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";
import { ReactComponent as GreetIcon } from "../../../icons/greet.svg";
import { ReactComponent as ArrowRightIcon } from "../../../icons/arrow-r.svg";

const NameBarMobile = () => {
  // const { data: userData } = useUserData();
  const userData = JSON.parse(localStorage.getItem("user"));

  const navigate = useNavigate();

  const OnNavigateClick = () => {
    navigate("/account-settings");
  };

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  return (
    <div className="name-bar-mobile" onClick={OnNavigateClick}>
      <div className="left">
        <GreetIcon className="icon" />
        <div className="greet">
          {Returntext(contentManagementData, "navbar_hello", lang)},{" "}
          {userData?.first_name}
        </div>
      </div>
      <div className="right">
        <div className="gz">
          {Returntext(contentManagementData, "navbar_room", lang)}{" "}
          {userData?.room_number}
        </div>
        <ArrowRightIcon className="icon" />
      </div>
    </div>
  );
};

export default NameBarMobile;
