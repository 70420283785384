import React from "react";
import "./customFooterS2.scss";
import BtnStandardIconText from "../../../ds/BtnStandardIconText/BtnStandardIconText";

const CustomFooterS2 = ({ handleUpdateCustomStatus, cancelClick, step }) => {
  return (
    <div className="custom-footer-s1">
      <BtnStandardIconText
        mode=""
        txt={"Cancel"}
        disabled={false}
        size={"standard"}
        icon={false}
        mainColor={"white"}
        onClick={() => cancelClick()}
      />
      <BtnStandardIconText
        mode=""
        txt={"Send to review"}
        disabled={step === "2" ? true : false}
        size={"standard"}
        icon={false}
        mainColor={"black"}
        onClick={() => handleUpdateCustomStatus()}
      />
    </div>
  );
};

export default CustomFooterS2;
