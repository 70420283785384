import ShippingAddressHeader from "../../components/molecules/ShippingAddressHeader/ShippingAddressHeader";
import ShippingAddressBody from "../../components/organisms/ShippingAddressBody/ShippingAddressBody";
import "./shippingAddress.scss";

import React, { useState } from "react";

const ShippingAddress = () => {
  const [country, setCountry] = useState("USA");

  return (
    <div className="shipping-address">
      <ShippingAddressHeader country={country} setCountry={setCountry} />
      <ShippingAddressBody />
    </div>
  );
};

export default ShippingAddress;
