import { useContent, useLanguages } from "../../hooks/content";
import { Returntext } from "../../pages/Landings/common/returnText";

function useCategoryArray() {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  // Assuming Returntext is a function that retrieves text from contentManagementData
  const categories = Array.from({ length: 25 }, (_, index) => {
    const categoryText = Returntext(
      contentManagementData,
      `Category_${index + 1}`,
      lang
    );
    return categoryText;
  });

  return categories;
}

export default useCategoryArray;
